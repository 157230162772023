<template>
  <div class="barcode-picker"/>
</template>

<script>
  import * as ScanditSDK from 'scandit-sdk'

  export default {
    data: () => ({
      scanner: null,
    }),
    methods: {
      async init () {
        await ScanditSDK.configure(process.env.VUE_APP_SCANDIT_LICENSE_KEY, {
          engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/'
        })

        this.scanner = await ScanditSDK.BarcodePicker.create(this.$el, {
          vibrateOnScan: true,
          videoFit: ScanditSDK.BarcodePicker.ObjectFit.COVER,
        })

        this.scanner.applyScanSettings(new ScanditSDK.ScanSettings({
          codeDuplicateFilter: 10000,
          enabledSymbologies: [
            ScanditSDK.Barcode.Symbology.EAN8,
            ScanditSDK.Barcode.Symbology.EAN13,
            ScanditSDK.Barcode.Symbology.UPCA,
            ScanditSDK.Barcode.Symbology.UPCE,
          ],
        }))

        this.scanner.on('scan', (result) => {
          result.barcodes.forEach(({ data }) => {
            this.$emit('input', data)
          })
        })
      },
    },
    mounted () {
      this.$nextTick(() => this.init())
    },
    beforeDestroy () {
      this.scanner.destroy()
    },
  }
</script>

<style scoped>
.barcode-picker {
  width: 100%;
  height: 270px;
}
</style>